import crypto from "crypto";
import BigNumber from "bignumber.js";
import * as starknet from "starknet";

import * as constants from "utils/constants";

const SHORT_STRING_MAX_CHARACTERS = 31;

export function generateRandomNonce() {
  let randomB = crypto.randomBytes(6);
  return parseInt(randomB.toString("hex"), 16);
}

/**
 * Truncates an ethereum address to the format 0x0000…0000
 * https://github.com/gpxl-dev/truncate-eth-address/blob/main/src/index.ts
 * @param address Full address to truncate
 * @returns Truncated address
 */
export function truncateAddress(address: string) {
  const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;
  const match = address?.match(truncateRegex);
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
}

/**
 * Given a string, truncates to 11 characters and append ...
 */
export function truncateIdentifier(value: string, length = 11) {
  if (value.length <= length) {
    return value;
  }
  return value.substring(0, length) + "…";
}

export async function waitFor(milliseconds: number) {
  return new Promise((resolve, _) => {
    setTimeout(() => {
      resolve(true);
    }, milliseconds);
  });
}

// https://spin.atomicobject.com/2020/01/16/timeout-promises-nodejs/
export async function withTimeout(
  timeoutMs: number,
  promise: () => Promise<any>
) {
  return Promise.race([
    promise(),
    new Promise((resolve, reject) => setTimeout(() => reject(), timeoutMs)),
  ]);
}

export function safeToBigNumber(amount: number | string): BigNumber {
  return new BigNumber(amount);
}

export function safeBigNumberToUint256(
  amount: BigNumber
): starknet.uint256.Uint256 {
  return starknet.uint256.bnToUint256(amount.toFixed());
}

export function safeUint256ToBigNumber(
  amount: starknet.uint256.Uint256
): BigNumber {
  return safeToBigNumber(starknet.uint256.uint256ToBN(amount).toString());
}

// https://stackoverflow.com/questions/4434076/best-way-to-alphanumeric-check-in-javascript
export function isAlphaNumeric(str: string) {
  var code, i, len;

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    if (
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123)
    ) {
      // lower alpha (a-z)
      return false;
    }
  }
  return true;
}

export function isASCII(str: string) {
  return /^[\x00-\x7F]*$/.test(str);
}

export function unsafeBigNumberToReadableString(value: BigNumber) {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 1,
    notation: "compact",
    compactDisplay: "short",
  }).format(Number(value));
}

export function bytesToHexString(value: Buffer): string {
  return starknet.encode.addHexPrefix(value.toString("hex"));
}

export function hexStringToBytes(value: string): Buffer {
  // remove hex prefix and all leading 0s
  let val = starknet.encode.removeHexPrefix(value).replace(/^0+/, "");
  if (val === "") {
    // handle edge case where the value is actually 0, should be `0x0` and not just 0x
    val = "0";
  }
  val = starknet.encode.addHexPrefix(val);
  const sanitizedValue = starknet.encode.sanitizeHex(val);
  return Buffer.from(starknet.encode.removeHexPrefix(sanitizedValue), "hex");
}

export function hexToBN(addr: string): starknet.number.BigNumberish {
  return starknet.number.toBN(starknet.encode.removeHexPrefix(addr), 16);
}

/**
 * Given two hex strings, compare that the values are equal. This is needed to be accurate as
 * "0x01" != "0x1" when comparing strings but the values are the same
 */
export function areHexStringsEqual(value1: string, value2: string) {
  if (
    (value1 === undefined && value2 !== undefined) ||
    (value1 !== undefined && value2 !== undefined)
  ) {
  }
  const value1BN = starknet.number.toBN(value1);
  const value2BN = starknet.number.toBN(value2);
  return value1BN.eq(value2BN);
}

/**
 * Checks if the given value is a vaild hex string
 */
export function isHexString(value: string) {
  try {
    const isHex = starknet.number.isHex(value);
    if (isHex === false) {
      return false;
    }
    starknet.number.toBN(value);
    return true;
  } catch (e) {
    return false;
  }
}

export function stringToFeltArray(longString: string): string[] {
  const res = [];

  const numChunks = Math.ceil(longString.length / SHORT_STRING_MAX_CHARACTERS);
  for (let i = 0, o = 0; i < numChunks; ++i, o += SHORT_STRING_MAX_CHARACTERS) {
    const chunk = longString.substr(o, SHORT_STRING_MAX_CHARACTERS);
    res.push(shortStringToBigInt(chunk).toString());
  }

  return res;
}

// https://github.com/Shard-Labs/starknet-hardhat-plugin/blob/master/src/extend-utils.ts#L46
function shortStringToBigInt(convertableString: string) {
  if (convertableString.length > SHORT_STRING_MAX_CHARACTERS) {
    const msg = `Short strings must have a max of ${SHORT_STRING_MAX_CHARACTERS} characters.`;
    throw new Error(msg);
  }

  const invalidChars: { [key: string]: boolean } = {};
  const charArray = [];
  for (const c of convertableString.split("")) {
    const charCode = c.charCodeAt(0);
    if (charCode > 127) {
      invalidChars[c] = true;
    }
    charArray.push(charCode.toString(16));
  }

  const invalidCharArray = Object.keys(invalidChars);
  if (invalidCharArray.length) {
    const msg = `Non-standard-ASCII character${
      invalidCharArray.length === 1 ? "" : "s"
    }: ${invalidCharArray.join(", ")}`;
    throw new Error(msg);
  }

  return BigInt("0x" + charArray.join(""));
}

export function getUint256CalldataFromBN(value: starknet.number.BigNumberish) {
  return getUint256Calldata(starknet.uint256.bnToUint256(value));
}

export function getUint256Calldata(value: starknet.uint256.Uint256) {
  return { type: "struct" as const, ...value };
}

export function isAspectContractAddress(contractAddress: string): boolean {
  return areHexStringsEqual(constants.ASPECT_CONTRACT_ADDRESS, contractAddress);
}

export function isBriqsContractAddress(contractAddress: string): boolean {
  return areHexStringsEqual(constants.BRIQ_CONTRACT_ADDRESS, contractAddress);
}

export function isMatchboxDAOContractAddress(contractAddress: string): boolean {
  return areHexStringsEqual(
    constants.MATCHBOXDAO_CONTRACT_ADDRESS,
    contractAddress
  );
}

export function isMintSquareContractAddress(contractAddress: string): boolean {
  return areHexStringsEqual(
    constants.MINTSQUARE_CONTRACT_ADDRESS,
    contractAddress
  );
}

export function isNOGameContractAddress(contractAddress: string): boolean {
  return areHexStringsEqual(constants.NOGAME_CONTRACT_ADDRESS, contractAddress);
}

export function rejectNotHandledSwitchStatement(res: never): never {
  throw new Error(
    "should not be possible, used to handle type guarded switch statements"
  );
}

/// https://stackoverflow.com/questions/10687099/how-to-test-if-a-url-string-is-absolute-or-relative
export function isAbsoluteUrl(url: string) {
  var r = new RegExp("^(?:[a-z]+:)?//", "i");
  return r.test(url);
}
