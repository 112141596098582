import { useEffect } from "react";

import { ProfileWithMetadata, populateProfile } from "utils/populate/profile";
import useProfileQuery from "hooks/data/useProfileQuery";
import useWalletsStore from "stores/useWalletsStore";

function useAutoConnectStarknetWallet() {
  const { connectStarknetWallet, updateStarknetWallet } = useWalletsStore(
    (state) => state
  );
  const { accountAddress, isInitialized } = useWalletsStore(
    (state) => state.starknetWallet
  );

  const { profile, loading } = useProfileQuery({
    profile_identifier: accountAddress,
    skip: !accountAddress,
  });

  useEffect(() => {
    // Add a timeout to allow for the wallets to have some time for wallet to inject the object
    setTimeout(() => {
      connectStarknetWallet({
        showModal: false,
      });
    }, 750);
  }, [connectStarknetWallet]);

  useEffect(() => {
    let profileWithMetadata: ProfileWithMetadata | undefined = undefined;
    if (profile) {
      profileWithMetadata = populateProfile(profile, accountAddress);
    }

    updateStarknetWallet({
      profileWithMetadata: profileWithMetadata,
      isProfileLoading: loading,
    });
  }, [updateStarknetWallet, isInitialized, accountAddress, profile, loading]);
}

export default useAutoConnectStarknetWallet;
