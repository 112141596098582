import { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/solid";

function Finish({
  isSuccess,
  title,
  description,
}: {
  isSuccess: boolean;
  title: string;
  description: string;
}) {
  return (
    <div className="flex items-start">
      <div className="flex-shrink-0 self-center">
        {isSuccess ? (
          <CheckCircleIcon
            className="h-8 w-8 text-green-400"
            aria-hidden="true"
          />
        ) : (
          <ExclamationCircleIcon
            className="h-8 w-8 text-red-400"
            aria-hidden="true"
          />
        )}
      </div>
      <div className="ml-3.5 w-0 flex-1 pt-0.5">
        <p
          className={`text-lg font-semibold font-sans ${
            isSuccess ? "text-green-800" : "text-red-800"
          }`}
        >
          {title}
        </p>
        <p
          className={`mt-1 text-sm whitespace-normal ${
            isSuccess ? "text-green-700" : "text-red-700"
          }`}
        >
          {description}
        </p>
      </div>
    </div>
  );
}

export default Finish;
