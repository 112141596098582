import * as Sentry from "@sentry/nextjs";
import { TransactionError } from "utils/errors";

export function setUser(accountAddress: string | undefined) {
  Sentry.setUser({
    id: accountAddress,
  });
}

export function captureError(err: any, tags?: any) {
  Sentry.withScope(function (scope) {
    if (
      err instanceof TransactionError ||
      "transactionHash" in err ||
      "transactionHash" in tags
    ) {
      tags.transactionHash = err.transactionHash;
      scope.setFingerprint([err.transactionHash]);
    }
    Sentry.captureException(err, {
      tags: tags,
    });
  });
}
