import React from "react";

function Bar({
  animationDuration,
  progress,
}: {
  animationDuration: number;
  progress: number;
}) {
  return (
    <div
      className="h-1 xl:h-1.5 w-full left-0 top-0 fixed z-50 rounded-full ease-in-out bg-gradient-to-r from-pink-400 via-red-400 to-yellow-400"
      style={{
        marginLeft: `${(-1 + progress) * 100}%`,
        transition: `margin-left ${animationDuration}ms`,
      }}
    ></div>
  );
}

// bg-gradient-to-r from-pink-400 via-red-400 to-yellow-400

export default Bar;
