import React from "react";
import {
  ActionToastStep,
  ActionToastSteps,
  ActionToastStepStatus,
} from "./ActionToastList.utils";

import { rejectNotHandledSwitchStatement } from "utils";

function Steps({ steps }: { steps: ActionToastSteps }) {
  function renderStep(step: ActionToastStep) {
    switch (step.status) {
      case ActionToastStepStatus.COMPLETE: {
        return (
          <div className="group pl-4 py-2 flex flex-col border-l-4 border-green-400 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
            <span className="text-xs text-neutral-700 font-semibold font-sans tracking-wide uppercase">
              {step.title}
            </span>
            <span className="text-sm font-medium">{step.description}</span>
          </div>
        );
      }
      case ActionToastStepStatus.CURRENT: {
        return (
          <div
            className="group pl-4 py-2 flex flex-col border-l-4 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
            style={{
              borderImage:
                "conic-gradient(from var(--angle), var(--c3), var(--c1) 0.1turn, var(--c2) 0.15turn, var(--c3) 0.25turn) 30",
              animation: "borderRotate var(--d) linear infinite forwards",
            }}
            aria-current="step"
          >
            <span className="text-xs text-neutral-600 font-semibold font-sans tracking-wide uppercase">
              {step.title}
            </span>
            <span className="text-sm font-medium truncate">
              {step.description}
            </span>
          </div>
        );
      }
      case ActionToastStepStatus.PENDING: {
        return (
          <div className="group pl-4 py-2 flex flex-col border-l-4 border-neutral-400 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
            <span className="text-xs text-neutral-500 font-semibold font-sans tracking-wide uppercase">
              {step.title}
            </span>
            <span className="text-sm font-medium truncate">
              {step.description}
            </span>
          </div>
        );
      }
      default: {
        // should not be possible, typescript should error if not all cases are handled
        rejectNotHandledSwitchStatement(step.status);
      }
    }
  }

  return (
    <nav aria-label="Progress">
      <ol role="list" className="space-y-4 md:flex md:space-y-0 md:space-x-8">
        {steps.map((step) => (
          <li key={step.id} className="md:flex-1">
            {renderStep(step)}
          </li>
        ))}
      </ol>
    </nav>
  );
}

export default Steps;
