import * as starknet from "starknet";

// Currently we only support one
export const STARKNET_GOERLI_CHAIN_ID = 5;

export const ZERO_BN = starknet.number.toBN(0);
export const ZERO_ADDRESS = starknet.number.toHex(ZERO_BN);

export const ASSET_ORDER_SIDE_BUY = 0;
export const ASSET_ORDER_SIDE_SELL = 1;

export const ASPECT_CONTRACT_ADDRESS =
  "0x04e34321e0bce0e4ff8ff0bcb3a9a030d423bca29a9d99cbcdd60edb9a2bf03a";
export const BRIQ_CONTRACT_ADDRESS =
  "0x0266b1276d23ffb53d99da3f01be7e29fa024dd33cd7f7b1eb7a46c67891c9d0";
export const NOGAME_CONTRACT_ADDRESS =
  "0x05bc2e73b537a0f3bdaf7e155fed1208b642d3c34b7512b4f3127c7773b957c6";
export const MINTSQUARE_CONTRACT_ADDRESS =
  "0x02f13075210b7252c826eafdc09d9d77ef272f582947f7adbd44ef79eae0062c";
export const MATCHBOXDAO_CONTRACT_ADDRESS =
  "0x5f0138e1b373c6e9db99c7c11751eb8dfcd14cc60ce8d067e76ffca843dfc8";
export const EXCHANGE_CONTRACT_ADDRESS =
  "0x037c320cf53f24747cb6afd1c23d0bf9ca33dcf8840764df11f8f8c8c0cd685c";
