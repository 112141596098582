import React, { useState, useRef } from "react";
import toast, { Toast } from "react-hot-toast";
import Draggable, {
  ControlPosition,
  DraggableEventHandler,
  DraggableEvent,
  DraggableData,
} from "react-draggable";

import { rejectNotHandledSwitchStatement } from "utils";
import useWindowDimensions from "hooks/common/useWindowDimensions";

import Finish from "./Finish";
import Steps from "./Steps";
import { ActionToast, ActionToastStatus } from "./ActionToastList.utils";

import LoadingSmall from "components/modules/Loading/LoadingSmall";

enum ActionToastPosition {
  HIDDEN = "HIDDEN",
  CENTER = "CENTER",
  BOTTOM_RIGHT = "BOTTOM_RIGHT",
}

const SNAP_WIDTH = 440;

function ActionToast({
  t,
  innerRef,
  offset,
}: {
  t: Toast;
  innerRef: (el: any) => void;
  offset: number;
}) {
  const [actionToastPosition, setActionToastPosition] =
    useState<ActionToastPosition>(ActionToastPosition.CENTER);

  const nodeRef = useRef(null);

  const { width } = useWindowDimensions();
  // 448 = length of ActionToast, 24 = from `right-6`
  const centerXPosition = -(width / 2 - 448 / 2 - 24);

  const POSITIONS: Record<ActionToastPosition, ControlPosition> = {
    [ActionToastPosition.HIDDEN]: { x: SNAP_WIDTH, y: 0 },
    [ActionToastPosition.BOTTOM_RIGHT]: { x: 0, y: 0 },
    [ActionToastPosition.CENTER]: { x: centerXPosition, y: 0 },
  };
  const position = POSITIONS[actionToastPosition];

  const handleDragStop: DraggableEventHandler = function (
    e: DraggableEvent,
    data: DraggableData
  ): void | false {
    // draggable from position CENTER <> BOTTOM_RIGHT <> HIDDEN
    if (data.x > position.x) {
      // always snap to hidden if anything is dragged to the right
      setActionToastPosition(ActionToastPosition.HIDDEN);
    } else if (
      actionToastPosition === ActionToastPosition.HIDDEN &&
      data.x < SNAP_WIDTH
    ) {
      setActionToastPosition(ActionToastPosition.BOTTOM_RIGHT);
    }
  };

  function handleOnMouseDown(e: MouseEvent): void {
    if (actionToastPosition === ActionToastPosition.HIDDEN) {
      setActionToastPosition(ActionToastPosition.BOTTOM_RIGHT);
    }
  }

  function renderActionToast(at: ActionToast) {
    switch (at.status) {
      case ActionToastStatus.SUCCESS: {
        return (
          <Finish
            isSuccess={true}
            title={"Success"}
            description={at.statusMessage}
          />
        );
      }
      case ActionToastStatus.FAILED: {
        return (
          <Finish
            isSuccess={false}
            title={"Failed"}
            description={at.statusMessage}
          />
        );
      }
      case ActionToastStatus.IN_PROGRESS: {
        if (actionToastPosition === ActionToastPosition.HIDDEN) {
          return <LoadingSmall />;
        }
        return <Steps steps={at.steps} />;
      }
      default: {
        // should not be possible, typescript should error if not all cases are handled
        rejectNotHandledSwitchStatement(at.status);
      }
    }
  }

  // TODO: hacky typing
  const actionToast = JSON.parse(t.message as string);
  if (!t.message) {
    return <></>;
  }

  return (
    <Draggable
      position={position}
      onStop={handleDragStop}
      onMouseDown={handleOnMouseDown}
      key={t.id}
      axis="x"
      nodeRef={nodeRef}
    >
      <div ref={nodeRef}>
        <div
          {...t.ariaProps}
          ref={innerRef}
          className={`${
            t.visible ? "animate-enter" : "animate-leave"
          } cursor-pointer max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 mb-4`}
          style={{
            transition: "all 0.5s ease-out",
            opacity: t.visible ? 1 : 0,
            transform: `translateY(${offset}px)`,
          }}
        >
          <div className="flex-1 w-full p-4 truncate">
            {renderActionToast(actionToast)}
          </div>
          <div className="flex flex-col justify-center border-l border-gray-200">
            <button
              onClick={() => {
                if (actionToastPosition !== ActionToastPosition.HIDDEN) {
                  setActionToastPosition(ActionToastPosition.HIDDEN);
                }
              }}
              className="w-full h-full border border-gray-200 rounded-none border-b border-t-0 border-l-0 border-r-0 p-2 flex items-center justify-center text-sm font-medium text-slate-600 hover:text-slate-500 focus:outline-none"
            >
              Hide
            </button>
            <button
              onClick={() => toast.dismiss(t.id)}
              className="w-full h-full border border-transparent rounded-none p-2 flex items-center justify-center text-sm font-medium text-slate-600 hover:text-slate-500 focus:outline-none"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </Draggable>
  );
}

export default ActionToast;
