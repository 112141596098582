import React, { useEffect } from "react";
import { useToaster } from "react-hot-toast";

import ActionToast from "./ActionToast";

function ActionToastList() {
  const { toasts, handlers } = useToaster();
  const { startPause, calculateOffset, updateHeight } = handlers;

  useEffect(() => {
    // make the action toast stay available by default
    startPause();
  }, []);
  // do not add startPause to the dependency array -- it changes on every render

  return (
    // DEVNOTE: the width needs to be inlined with the centerXPosition in the ActionToast component
    <div className="fixed bottom-6 right-6 max-w-md w-full z-50">
      {toasts.map((t) => {
        const offset = calculateOffset(t, {
          reverseOrder: false,
          defaultPosition: "bottom-right",
        });

        // copied from https://react-hot-toast.com/docs/use-toaster
        // https://codesandbox.io/s/react-hot-toast-usetoaster-headless-example-zw7op?file=/src/App.js
        const ref = (el: any) => {
          if (el && !t.height) {
            const height = el.getBoundingClientRect().height;
            updateHeight(t.id, height);
          }
        };

        return <ActionToast key={t.id} t={t} innerRef={ref} offset={offset} />;
      })}
    </div>
  );
}

export default ActionToastList;
