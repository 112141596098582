import * as starknet from "starknet";
import create, { SetState, GetState } from "zustand";
import { getStarknet, disconnect } from "get-starknet";

import { withTimeout } from "utils";
import * as sentry from "utils/sentry";
import { ProfileWithMetadata } from "utils/populate/profile";

export enum WalletStatus {
  DISCONNECTED = "DISCONNECTED",
  CONNECTED = "CONNECTED",
}

type StarknetWallet = {
  accountAddress: string;
  provider: starknet.Provider | null;
  account: starknet.Account | null;
  status: WalletStatus;
  profileWithMetadata: ProfileWithMetadata | null;

  // indicates if the wallet has been initialized for the first time
  // does not indicate the profile metadata has been loaded
  isInitialized: boolean;

  // indicates if the profile has been loaded
  isProfileLoading: boolean;
};

type WalletStore = {
  starknetWallet: StarknetWallet;
  connectStarknetWallet: (options?: { showModal: boolean }) => Promise<void>;
  updateStarknetWallet: (updates: any) => void;
  maybeUpdateStarknetWallet: () => Promise<void>;
  disconnectStarknetWallet: () => Promise<void>;
};

const useWalletsStore = create<WalletStore>(
  (set: SetState<WalletStore>, get: GetState<WalletStore>) => ({
    starknetWallet: {
      accountAddress: "",
      provider: null,
      account: null,
      status: WalletStatus.DISCONNECTED,
      profileWithMetadata: null,
      isInitialized: false,
      isProfileLoading: true,
    },

    /**
     * Helper to connect to the argent starknet wallet.
     * - Request argent for current website to access if not already granted
     */
    connectStarknetWallet: async (options?: { showModal?: boolean }) => {
      const starknet = getStarknet();

      if (get().starknetWallet.isInitialized) {
        await starknet.enable({
          showModal: options?.showModal,
        });
      } else {
        // enable waits for the user to respond before returning, add a timeout
        // in the case where it is the first request to connect
        try {
          await withTimeout(1000 /* 1 seconds */, () =>
            starknet.enable({
              showModal: options?.showModal,
            })
          );
        } catch (err) {
          // ignore the error and carry on updating the starknet wallet state
        }
      }

      await get().maybeUpdateStarknetWallet();

      if (starknet.isConnected) {
        starknet.on("accountsChanged", async () => {
          await get().maybeUpdateStarknetWallet();
        });
      }
    },

    updateStarknetWallet: (updates: any) => {
      return set((state) => ({
        starknetWallet: { ...state.starknetWallet, ...updates },
      }));
    },

    /**
     * Update the starknet wallet details if they are available
     * - will not request any updates to user
     */
    maybeUpdateStarknetWallet: async () => {
      const starknet = getStarknet();

      const status = starknet.isConnected
        ? WalletStatus.CONNECTED
        : WalletStatus.DISCONNECTED;

      get().updateStarknetWallet({
        status: status,
        accountAddress: starknet.selectedAddress,
        provider: starknet.provider,
        account: starknet.account,

        isInitialized: true,
      });

      // add user for global sentry logging
      sentry.setUser(starknet.selectedAddress);
    },

    disconnectStarknetWallet: async () => {
      disconnect({
        clearLastWallet: true,
        clearDefaultWallet: true,
      });
      await get().maybeUpdateStarknetWallet();
    },
  })
);

export default useWalletsStore;
