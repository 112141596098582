import { Profile } from "utils/graphql/generated";
import { areHexStringsEqual, truncateAddress, truncateIdentifier } from "utils";
import * as paths from "utils/paths";

import useWalletsStore from "stores/useWalletsStore";

const ProfileWithMetadataInternalTypeName = "ProfileWithMetadata";
export function isProfileWithMetadata(val: any) {
  return val?.__internal_type === ProfileWithMetadataInternalTypeName;
}

export interface ProfileWithMetadata extends Profile {
  __internal_type: string;

  // display identifier that does not include logic to display `you`
  displayIdentifierRaw: string;

  // short string to dislay raw identifier
  displayIdentifierRawShort: string;

  // either the address or username of user
  displayIdentifier: string;

  // short string to display the identifier
  displayIdentifierShort: string;

  // optional display profile image url
  displayProfileImageUrl: string | null;

  // optional display banner image url
  displayBannerImageUrl: string | null;

  // url to route to profile
  routeUrl: string;
}

export function populateProfiles(
  profiles: Profile[],
  currentAccountAddress: string
): ProfileWithMetadata[] {
  return profiles.map((profile) => {
    return populateProfile(profile, currentAccountAddress);
  });
}

export function populateProfile(
  profile: Profile,
  currentAccountAddress: string
): ProfileWithMetadata {
  const isCurrentAccountProfile = areHexStringsEqual(
    currentAccountAddress,
    profile.account_address
  );

  let displayIdentifierRaw = profile.account_address;
  let displayIdentifierRawShort = truncateAddress(profile.account_address);
  if (profile.username) {
    displayIdentifierRaw = profile.username;
    displayIdentifierRawShort = truncateIdentifier(profile.username);
  }

  let displayIdentifier = displayIdentifierRaw;
  let displayIdentifierShort = displayIdentifierRawShort;
  if (isCurrentAccountProfile) {
    displayIdentifier = "you";
    displayIdentifierShort = "you";
  }

  const displayProfileImageUrl = profile.profile_image_url || null;
  const displayBannerImageUrl = profile.banner_image_url || null;
  const routeUrl = paths.profile(profile.username || profile.account_address);

  return {
    ...profile,
    __internal_type: ProfileWithMetadataInternalTypeName,

    displayIdentifierRaw,
    displayIdentifierRawShort,
    displayIdentifier,
    displayIdentifierShort,

    displayProfileImageUrl,
    displayBannerImageUrl,

    routeUrl,
  };
}
