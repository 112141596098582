import toast from "react-hot-toast";

export type ActionToastSteps = Array<ActionToastStep>;

export type ActionToast = {
  // unique internal uuid
  id: string;

  // status of the overall toast progress
  status: ActionToastStatus;
  statusMessage: string;

  steps: ActionToastSteps;
};

// Status for the overall toast progress
export enum ActionToastStatus {
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

export type ActionToastStep = {
  id: string;
  title: string;
  description: string;
  status: ActionToastStepStatus;
  link?: string;
};

export enum ActionToastStepStatus {
  COMPLETE = "COMPLETE",
  CURRENT = "CURRENT",
  PENDING = "PENDING",
}

export type UpdateActionToastFnType = (actionToast: ActionToast) => void;

/**
 * adds an action toast, if the toastId is set, update instead
 *
 * returns a wrapper function that can be used to the given toast
 */
export function addActionToast(
  actionToast: ActionToast
): UpdateActionToastFnType {
  const toastId = toast(JSON.stringify(actionToast));
  function updateActionToast(at: ActionToast) {
    toast(JSON.stringify(at), {
      id: toastId,
    });
  }
  return updateActionToast;
}
