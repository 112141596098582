export class AssetHasBeenPurchasedError extends Error {
  constructor() {
    const errorMessage = "this nft has been purchased";
    super(errorMessage);
  }
}
export class NotEnoughTokensError extends Error {
  constructor() {
    const errorMessage = "not enough tokens";
    super(errorMessage);
  }
}
export class TransactionError extends Error {
  transactionHash = "";

  constructor(message: string, transactionHash: string) {
    super(message);
    this.transactionHash = transactionHash;
  }
}

/**
 * Helper function to check for errors that we expect from the wallet
 * returns
 * - null if not expected error
 * - string with message if expected error
 */
export function isExpectedError(err: unknown): string | null {
  if (isWalletRejectError(err)) {
    return "Wallet request was rejected.";
  } else if (isWalletNonceError(err)) {
    return "Wallet nonce error. Please try again.";
  } else if (isWalletFeeError(err)) {
    return "Insufficient ETH to pay for fees. Please add more ETH to your wallet.";
  } else if (isAssetDoesNotExistError(err)) {
    return "NFT was not found.";
  } else if (isContractDoesNotExistError(err)) {
    return "Collection was not found.";
  } else if (err instanceof NotEnoughTokensError) {
    return err.message;
  } else if (err instanceof AssetHasBeenPurchasedError) {
    return err.message;
  }
  return null;
}

export function isAssetDoesNotExistError(err: unknown) {
  if (err instanceof Error) {
    // error from backend
    return err.message.toLowerCase().includes("asset does not exist");
  }
  return false;
}

export function isContractDoesNotExistError(err: unknown) {
  if (err instanceof Error) {
    // error from backend
    return err.message.toLowerCase().includes("contract does not exist");
  }
  return false;
}

/**
 * Checks if the given object is an error object and also is
 * also a user rejection from the wallet
 */
export function isWalletNonceError(err: unknown) {
  if (err instanceof Error) {
    // error from argent
    return err.message.toLowerCase().includes("nonce invalid");
  }
  return false;
}

/**
 * Checks if the given object is an error object and also is
 * also a user rejection from the wallet
 */
export function isWalletRejectError(err: unknown) {
  if (err instanceof Error) {
    return (
      // argent wallet
      err.message.toLowerCase().includes("user abort") ||
      // braavos wallet
      err.message.toLowerCase().includes("execute failed")
    );
  }
  return false;
}

/**
 * Checks if the given object is an error object and also is
 * an error due to the user not having enough fees
 */
export function isWalletFeeError(err: unknown) {
  if (err instanceof Error) {
    return err.message.toLowerCase().includes("fee_transfer_failure");
  }
  return false;
}

/**
 * Checks if the given object is an error object and also is
 * also a rejection that the username already exists
 */
export function isUsernameAlreadyExistsError(err: unknown) {
  if (err instanceof Error) {
    return err.message.toLowerCase().includes("username already exists");
  }
  return false;
}
