
import useNetworkDetector from "hooks/chain/useNetworkDetector"
import useAutoConnectStarknetWallet from "hooks/chain/useAutoConnectStarknetWallet"

function GlobalHooks() {
  useNetworkDetector();
  useAutoConnectStarknetWallet();

  return <></>
}

export default GlobalHooks