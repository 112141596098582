import { useEffect } from "react";
import { useRouter } from "next/router";

import { getNetworkFromProvider, StarknetNetworkType } from "utils/stark";
import * as paths from "utils/paths";

import useWalletsStore from "stores/useWalletsStore";

function useNetworkDetector() {
  const router = useRouter();
  const { provider } = useWalletsStore((state) => state.starknetWallet);

  // TODO: probably better to use networkChanged event from get-starknet
  useEffect(() => {
    if (provider) {
      const networkType = getNetworkFromProvider(provider);
      if (networkType !== StarknetNetworkType.GOERLI) {
        if (router.pathname !== paths.network) {
          router.push(paths.network);
        }
      }
    }
  }, [provider, router]);
}

export default useNetworkDetector;
