import "styles/globals.css";
import type { ReactElement, ReactNode } from "react";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import Script from "next/script";
import { ApolloProvider } from "@apollo/client";

import * as config from "utils/config";
import { useApollo } from "utils/graphql/client";
import { CardTagsMeta } from "utils/cardtags";

import ActionToastList from "components/modules/ActionToastList";
import Progress from "components/common/Progress";
import GlobalHooks from "components/modules/GlobalHooks"

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement, cardTagsMeta?: CardTagsMeta) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const apolloClient = useApollo(pageProps);


  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <ApolloProvider client={apolloClient}>
      {getLayout(
        <>
          <GlobalHooks />
          <Script
            strategy="lazyOnload"
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GOOGLE_ANALYTICS_ID}`}
          />
          <Script id="google-analytics-setup" strategy="lazyOnload">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${config.GOOGLE_ANALYTICS_ID}');
            `}
          </Script>
          <Progress />
          <Component {...pageProps} />
          <ActionToastList />
        </>, pageProps.cardTagsMeta
      )}
    </ApolloProvider>
  );
}

export default MyApp;
