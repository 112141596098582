import React from "react";
import styles from "./LoadingSmall.module.css";

function LoadingSmall() {
  return (
    <div className={`${styles.loader}`}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

export default LoadingSmall;
