import * as starknet from "starknet";
import {
  safeToBigNumber,
  waitFor,
  getUint256Calldata,
  hexStringToBytes,
  bytesToHexString,
} from "utils";
import { TransactionError } from "utils/errors";

export enum StarknetNetworkType {
  MAINNET_ALPHA = "MAINNET_ALPHA",
  GOERLI = "GOERLI",
  LOCALHOST = "LOCALHOST",
}

// returns the network from the provider
// https://github.com/argentlabs/argent-x/blob/main/packages/playground/src/services/wallet.service.ts#L18
export function getNetworkFromProvider(provider: starknet.Provider) {
  const { baseUrl } = provider;
  try {
    if (baseUrl.includes("alpha-mainnet.starknet.io")) {
      return StarknetNetworkType.MAINNET_ALPHA;
    } else if (baseUrl.includes("alpha4.starknet.io")) {
      return StarknetNetworkType.GOERLI;
    } else if (baseUrl.match(/^https?:\/\/localhost.*/)) {
      return StarknetNetworkType.LOCALHOST;
    }
  } catch {
    throw new Error(`Unexpected network type: ${baseUrl}`);
  }
}

export async function fetchTokenBalance(
  provider: starknet.Provider,
  walletAddress: string,
  tokenContractAddress: string
) {
  const res = await provider?.callContract({
    contractAddress: tokenContractAddress,
    entrypoint: "balanceOf",
    calldata: starknet.stark.compileCalldata({
      user: walletAddress,
    }),
  });
  const result = res["result"];
  return safeToBigNumber(
    starknet.uint256
      .uint256ToBN({
        low: result[0],
        high: result[1],
      })
      .toString()
  );
}

export async function erc721OwnerOf(
  provider: starknet.Provider,
  contractAddress: string,
  contractTokenId: starknet.uint256.Uint256
) {
  const res = await provider?.callContract({
    contractAddress: contractAddress,
    entrypoint: "ownerOf",
    calldata: starknet.stark.compileCalldata({
      tokenId: getUint256Calldata(contractTokenId),
    }),
  });
  const result = res["result"];
  return result[0];
}

export async function waitForTxWrapper(
  provider: starknet.Provider,
  txHash: string
) {
  while (true) {
    try {
      await waitForTransaction(provider, txHash);
      return;
    } catch (err: any) {
      // @ts-ignore
      if (err.response?.status === 503 || err.response?.status === 429) {
        console.log("retrying...");
        await waitFor(5000 /* 5 seconds*/);
      } else if (err instanceof Error) {
        const transactionError = err as TransactionError;
        transactionError.transactionHash = txHash;
        throw transactionError;
      } else {
        throw err;
      }
    }
  }
}

// TODO this is a temporary hack around starknet.js to support successStates that do not include PENDING
// we don't include PENDING because our indexer currently looks at ACCEPTED_ON_L2 blocks as finality
async function waitForTransaction(
  provider: starknet.Provider,
  txHash: starknet.number.BigNumberish,
  retryInterval: number = 8000
) {
  let onchain = false;

  while (!onchain) {
    // eslint-disable-next-line no-await-in-loop
    await waitFor(retryInterval);
    // eslint-disable-next-line no-await-in-loop
    const res = await provider.getTransactionStatus(txHash);

    const successStates = ["ACCEPTED_ON_L1", "ACCEPTED_ON_L2"];
    const errorStates = ["REJECTED", "NOT_RECEIVED'"];

    if (successStates.includes(res.tx_status)) {
      onchain = true;
    } else if (errorStates.includes(res.tx_status)) {
      const message = res.tx_failure_reason
        ? `${res.tx_status}: ${res.tx_failure_reason.code}\n${res.tx_failure_reason.error_message}`
        : res.tx_status;
      const error = new Error(message) as Error & {
        response: starknet.GetTransactionStatusResponse;
      };
      error.response = res;
      throw error;
    }
  }
}

export function getVoyagerTransactionLink(txHash: string) {
  const checksumTxHash = bytesToHexString(hexStringToBytes(txHash));
  return `https://goerli.voyager.online/tx/${checksumTxHash}`;
}

export function getVoyagerContractAddressLink(contractAddress: string) {
  const checksumTxHash = bytesToHexString(hexStringToBytes(contractAddress));
  return `https://goerli.voyager.online/contract/${checksumTxHash}`;
}
