export function profile(accountIdentifier: string) {
  return `/${accountIdentifier}`;
}

export function collection(contractAddress: string) {
  return `/collection/${contractAddress}`;
}

export function asset(contract_address: string, token_id: string) {
  return `/asset/${contract_address}/${token_id}`;
}

export const network = "/network";
export const status = "/status";
export const buy = "/buy";
export const sell = "/sell";
export const create = "/create";

export function removeQueryParams(asPath: string) {
  return asPath.split("?")[0];
}
