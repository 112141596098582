import { ApolloClient, ApolloError, gql, useQuery } from "@apollo/client";
import { Profile, QueryProfileArgs } from "utils/graphql/generated";

export const USE_PROFILE_QUERY_NAME = "profile";

type ProfileData = {
  profile?: Profile;
};

type ProfileQueryResponse = ProfileData & {
  error?: ApolloError;
  loading: boolean;
};

const ProfileGraphql = gql`
  query profile($input: ProfileInput!) {
    profile(input: $input) {
      account_address
      username
      biography
      profile_image_url
      banner_image_url
    }
  }
`;

export async function getProfile({
  client,
  profile_identifier,
}: {
  client: ApolloClient<any>;
  profile_identifier: string;
}) {
  const { data } = await client.query<ProfileData, QueryProfileArgs>({
    query: ProfileGraphql,
    variables: {
      input: {
        profile_identifier: profile_identifier,
      },
    },
  });

  return data?.profile;
}

function useProfileQuery({
  profile_identifier,
  skip,
}: {
  profile_identifier: string;
  skip?: boolean;
}): ProfileQueryResponse {
  const { data, error, loading } = useQuery<ProfileData, QueryProfileArgs>(
    ProfileGraphql,
    {
      variables: {
        input: {
          profile_identifier: profile_identifier,
        },
      },
      skip: skip,
    }
  );

  let profile = data?.profile;
  return { profile, error, loading };
}

export default useProfileQuery;
